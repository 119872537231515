.video-item {
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
}

.video-item.item img {
  max-width: 180px;
}

.video-title {
  color: #fff !important;
  font-variant: small-caps;
  font-weight: 100;
  font-size: 1.2em;
  line-height: 1.5em;
}
